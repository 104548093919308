<template>
    <span>{{ countDown }}</span>
</template>

<script>
export default {
    data() {
        return {
            thisTime: 0,
            timer: '', // 公共定时器
            countDown: '', // 倒计时
        };
    },
    props: {
        // 倒计时间总秒数
        time: {
            default: '0',
        },
        // 格式 YY 年 MM 月 DD 天数 hh 小时 mm 分钟 ss 秒数
        format: {
            default: 'hh:mm:ss',
        },
        // 递增模式
        add: {
            default: false,
        },
        // 自动补足两位数
        padStart: {
            default: true,
        },
    },
    methods: {
        // 处理格式
        handleFormat() {
            let unitArr = [
                { name: 'YY', unit: 12 * 30 * 24 * 60 * 60 * 1 },
                { name: 'MM', unit: 30 * 24 * 60 * 60 * 1 },
                { name: 'DD', unit: 24 * 60 * 60 * 1 },
                { name: 'hh', unit: 60 * 60 * 1 },
                { name: 'mm', unit: 60 * 1 },
                { name: 'ss', unit: 1 },
            ];
            let _format = this.format;
            let _time = this.thisTime;
            let str = '';
            unitArr.forEach(item => {
                let arr = _format.split(item.name);
                if (arr.length === 2) {
                    let count = String(Math.floor(_time / item.unit));
                    this.padStart && (count = count.padStart(2, '0'));
                    str += arr[0] + count;
                    _time = _time - count * item.unit;
                    _format = arr[1];
                }
            });
            str += _format;
            this.countDown = str;
        },
        // 倒计时
        toCountDown() {
            const that = this;
            clearInterval(this.timer);
            this.thisTime = this.time;
            that.handleFormat();
            this.timer = setInterval(function () {
                // console.log('定时器');
                that.add ? (that.thisTime += 1) : (that.thisTime -= 1);
                that.handleFormat();
                if (that.thisTime <= 0) {
                    clearInterval(that.timer);
                    that.$emit('end');
                    // console.warn('定时结束');
                }
            }, 1000);
        },
    },
    watch: {
        // time: {
        //     handler(val) {
        //         this.thisTime = this.time;
        //         this.handleFormat();
        //         console.log(this.format);
        //         if (this.time > 0) {
        //             this.toCountDown();
        //         }
        //     },
        //     immediate: true,
        // },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
