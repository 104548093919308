<template>
    <div>
        <template v-for="(item, index) in options">
            <div v-if="item.dictValue==value">
                <el-tooltip :disabled="!tip" class="item" effect="dark" :content="tip" placement="top">
                    <div>
                        <el-tag
                            :disable-transitions="true"
                            :key="item.value"
                            :index="index"
                            :type="item.listClass == 'primary' ? '' : item.listClass"
                        >
                            {{ item.dictLabel }}
                        </el-tag>
                    </div>
                </el-tooltip>
            </div>
        </template>


    </div>
</template>

<script>
export default {
    name: "DictTag",
    props: {
        options: {
            type: Array,
            default: null,
        },
        value: [Number, String, Array],
        tip: String
    },
    computed: {
        values() {
            if (this.value !== null && typeof this.value !== 'undefined') {
                console.info("fff", Array.isArray(this.value) ? this.value : [String(this.value)])
                return Array.isArray(this.value) ? this.value : [String(this.value)];
            } else {
                return [];
            }
        },
    },
};
</script>
<style scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}
</style>
