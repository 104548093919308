<template>
    <div class="common-layout">
        <el-container class="common-container">
            <el-header class="common-header flex-float">
                <div style="width: 200px">
                    <!-- <img class="logo" src="@/assets/logo.png" alt=""> -->
                    <h1 style="color: white;font-size: 16px;font-weight: 900">蓝证实名证照(照相馆版)</h1>
                </div>
                <div>
                    <el-button v-if="nickName" type="primary" size="small" @click="showSetting" plain>{{ nickName }}
                    </el-button>
                    <el-button type="danger" @click="logout" size="small"><i class="el-icon-user"></i>&nbsp;退出
                    </el-button>
                </div>
            </el-header>
            <el-container>
                <el-menu style="min-width: 65px" class="el-menu-vertical-demo" background-color="rgb(26, 56, 97)"
                         text-color="#fff" :router="true" :default-active="activeMenu"
                         @open="handleOpen" @close="handleClose" :collapse="isCollapse">
                    <!--                    <el-menu-item index="/photoUpload">-->
                    <!--                        <i class="el-icon-tickets"></i>-->
                    <!--                        <span slot="title">照片上传</span>-->
                    <!--                    </el-menu-item>-->
                    <el-menu-item index="/photo">
                        <i class="el-icon-camera"></i>
                        <span slot="title">照片列表</span>
                    </el-menu-item>
                </el-menu>
                <!--        </el-aside>-->
                <el-main class="common-main">
                    <Bread @collapse="collapse"/>
                    <router-view/>
                </el-main>
            </el-container>
        </el-container>
        <el-drawer
            title="我是标题"
            :visible.sync="drawer"
            :with-header="false">
            <el-form ref="loginForm" class="login-form" style="margin: 50px;">
                <h3 class="title">蓝证目录设置</h3>
                <el-form-item prop="pcPath" label="电脑拍摄(EOS软件设置的照片存放目录)">
                    <el-input
                        v-model="pcPath"
                        type="text"
                        auto-complete="off"
                        placeholder="电脑拍摄(EOS软件设置的照片存放目录)"
                        @keyup.enter.native="hanleSaveDict"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item prop="dzz" label="待制作目录">
                    <el-input
                        v-model="dzz"
                        type="text"
                        auto-complete="off"
                        placeholder="待制作"
                        @keyup.enter.native="hanleSaveDict"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item prop="yzz" label="已制作目录">
                    <el-input
                        v-model="yzz"
                        type="text"
                        auto-complete="off"
                        placeholder="已制作"
                        @keyup.enter.native="hanleSaveDict"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item style="width:100%;">
                    <el-button
                        :loading="loading"
                        size="medium"
                        type="primary"
                        style="width:100%;"
                        @click.native.prevent="hanleSaveDict"
                    >
                        <span v-if="!loading">保存</span>
                        <span v-else>登 录 中...</span>
                    </el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
        <!--        <WebSocket/>-->
        <el-dialog :visible.sync="openSetting" title="修改密码" width="500px">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="旧密码" prop="oldPassword">
                    <el-input v-model="form.oldPassword" type="password" placeholder="请输入旧密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input v-model="form.newPassword" type="password" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="rePassword">
                    <el-input v-model="form.rePassword" type="password" placeholder="确认新密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="" size=""
                               @click="submitForm('form')">确定
                    </el-button>
                    <el-button icon="" size="" @click="resetForm('form')">取消</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
    </div>

</template>

<script>
import Bread from '@/layout/Bread.vue'
import * as api from '@/api/api'
import {MessageBox} from "element-ui";
import {mapGetters} from 'vuex'
import {isRelogin} from "@/utils/request";

export default {
    components: {
        Bread,
    },
    data() {
        const validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            isCollapse: true,
            loading: false,
            drawer: false,
            pcPath: "",
            dzz: "",
            yzz: "",
            openSetting:false,
            form:{},
            rules: {
                oldPassword: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                    { min: 6, message: '密码长度不能少于6位', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, message: '密码长度不能少于6位', trigger: 'blur' }
                ],
                rePassword: [
                    { required: true, message: '请确认密码', trigger: 'blur' },
                    { validator: validatePassword, trigger: 'blur' }
                ],
            }
        }
    },
    created() {
        // this.handleGetDict()
    },

    computed: {
        ...mapGetters(['nickName']),
        activeMenu() {
            const route = this.$route;
            const {meta, path} = route;
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    api.updatePwd(this.form).then(res=>{
                        this.$message.success("修改成功")
                        this.openSetting=false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.openSetting=false
        },
        showSetting(){
            this.openSetting=true
        },
        collapse(value) {
            this.isCollapse = value
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        async logout() {
            await MessageBox.confirm('确定退出吗', '系统提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            await this.$store.dispatch("user/Logout")
            location.href = process.env.VUE_APP_CONTEXT_PATH + "#/login";


            // MessageBox.confirm('确定退出吗', '系统提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //
            //     localStorage.removeItem("token")
            //     location.href = process.env.VUE_APP_CONTEXT_PATH + "#/login";
            // }).catch(() => {
            // });
        },
        hanleSaveDict() {
            api.saveDict({
                dzz: this.dzz,
                yzz: this.yzz,
                pcPath: this.pcPath
            }).then(res => {
                this.$modal.msgSuccess(res.msg);
                this.drawer = false
            })
        },
        handleGetDict() {
            this.drawer = true
            api.getDict().then(res => {
                console.info(res)
                this.dzz = res.data.dzz
                this.yzz = res.data.yzz
                this.pcPath = res.data.pcPath
            })
        },
    }
}
</script>

<style lang="scss">
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.common-container {
    height: 100vh;
    // overflow: hidden;
}

.common-header {
    background-color: rgb(26, 56, 97);
    display: flex;
}

.common-aside {
    //background-color: rgb(48, 55, 65);
    background-color: rgb(26, 56, 97);
}

.common-main {
    // height: 2000px;
}

.logo {
    width: 80px;
}

.title {
    color: #fff
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        .router-link-exact-active {
            color: #42b983;
        }
    }
}

.flex-float {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex {
    display: flex;
    align-items: center;
}
</style>
