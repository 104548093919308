<template>
  <div id="page">
    <el-card>
      <div class="search-content">
          <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
            <el-form-item label="姓名">
              <el-input v-model="queryParams.name" placeholder="姓名" clearable></el-input>
            </el-form-item>
            <el-form-item label="身份证">
              <el-input v-model="queryParams.idCard" placeholder="身份证" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini"
                         @click="handleQuery(true)">搜索
              </el-button>
              <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button> -->
            </el-form-item>
          </el-form>
      </div>
    </el-card>
    <el-card>
      <div class="opt-btn">
        <el-button type="primary" @click="handleAdd" size="mini" icon="el-icon-edit">添加</el-button>
        <el-button type="danger" @click="handleDel" :disabled="multiple" size="mini"
                   icon="el-icon-delete">删除
        </el-button>
        <el-button type="success" @click="handleUpload" :disabled="multiple" size="mini"
                   icon="el-icon-cloudy">上传
        </el-button>
        <!--                <el-button type="danger" @click="handleExport" size="small" icon="el-icon-download">导出excel</el-button>-->
        <!--                <el-button type="danger" @click="handleExportPhoto" size="small" icon="el-icon-download">导出图片</el-button>-->
      </div>
    </el-card>
    <el-card>
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="照片" align="center" prop="oriFilePath" width="100">
          <template slot-scope="scope">
            <el-image style="width: 72px; height: 82px" :src="(baseUrl + scope.row.oriFilePath)"
                      :preview-src-list="[baseUrl + scope.row.oriFilePath]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="">
        </el-table-column>
        <el-table-column prop="idCard" label="身份证" width="">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" width="">
        </el-table-column>
        <el-table-column prop="labelName" label="标签" width="">
        </el-table-column>
        <el-table-column prop="oriFileName" label="照片名称"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">编辑</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click.stop="handleDel(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="queryParams.pageNum" :page-sizes="[10, 50, 100, 500]"
                     :page-size="queryParams.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>


    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="身份证号" prop="certNumber">
          <el-input v-model="form.idCard" placeholder="身份证"/>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="姓名"/>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="手机号"/>
        </el-form-item>
        <el-form-item label="标签" prop="标签">
          <el-input v-model="form.labelName" placeholder="标签"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {query, add, update, del, get,upload} from '@/api/crud'

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      tableData: [],
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 岗位表格数据
      postList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: "updateTime",
        isAsc: "desc"

        // postCode: undefined,
      },
      // 表单参数
      form: {},
      rules: {}
    };
  },
  created() {
    this.handleQuery();
  },
  mounted(){
    var that=this
    window.addEventListener('message', function(e){
      console.log(e)
      that.handleQuery();
    })
  },
  methods: {
    // onMessage(ee){
    //   alert(ee)
    // },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      console.info(this.ids)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        // postId: undefined,
        // postCode: undefined,
      };
      this.resetForm('form')
    },
    resetQuery() {
      this.resetForm('queryForm')
      this.handleQuery(true);
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.handleQuery();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.handleQuery();
    },
    handleQuery(val) {
      if (val == true) {
        this.queryParams.pageNum = 1
      }
      query(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      get(id).then(res => {
        this.form = res.data;
        this.open = true;
        this.title = "修改";
      });
    },
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            update(this.form).then(res => {
              this.$message({message: res.msg, type: 'success'})
              this.open = false;
              this.handleQuery(true);
            });
          } else {
            add(this.form).then(res => {
              this.$message({message: res.msg, type: 'success'})
              this.open = false;
              this.handleQuery(true);
            });
          }
        }
      });
    },
    handleDel(row) {
      const ids = row.id || this.ids;
      this.$confirm(`确定删除[${ids}]吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(ids).then((res) => {
          if (res.code == 200) {
            this.$message({message: res.msg, type: 'success'})
            this.handleQuery()
          }
        });
      })
    },
    handleUpload(row) {
      const ids = row.id || this.ids;
      this.$confirm(`确定上传[${ids}]吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        upload(ids).then((res) => {
          console.info(res)
          if (res.code == 200) {
            // this.$message({message: res.msg, type: 'success'})

            let msg="成功上传:<font color='red'>"+res.data.successCnt+"</font>张照片</br>"
            if(res.data.errorIdCards!=''){
              msg+="失败身份证:"+res.data.errorIdCards
            }
            this.$alert(msg, '提示', {
              confirmButtonText: '确定',
              dangerouslyUseHTMLString: true
            });
            this.handleQuery()
          }
        });
      })
    },
    handleExport() {
      this.download('/personinfo/export', {
        ...this.queryParams
      }, `${this.$dateUtil.getFullDateTime()}.xlsx`)
    },
    handleExportPhoto() {
      this.download('/personinfo/exportPhoto', {
        ...this.queryParams
      }, `${this.$dateUtil.getFullDateTime()}.zip`)
    },
  },
}
</script>

<style lang="scss">
.opt-btn {
  display: flex;
}

.search-content {
  display: flex;
  align-items: center;
}
</style>