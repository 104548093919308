<template>
    <div class="input_box flexbox">
        <!-- 单元格页面 -->
        <div class="codes">
            <!-- 通过长度与获取焦点标签控制单元格是否高亮 -->
            <div
                class="code_item"
                :class="codeValue.length == 0 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[0] }}
            </div>
            <div
                class="code_item"
                :class="codeValue.length == 1 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[1] }}
            </div>
            <div
                class="code_item"
                :class="codeValue.length == 2 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[2] }}
            </div>
            <div
                class="code_item"
                :class="codeValue.length == 3 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[3] }}
            </div>
            <div
                class="code_item"
                :class="codeValue.length == 4 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[4] }}
            </div>
            <div
                class="code_item"
                :class="codeValue.length == 5 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[5] }}
            </div>
            <div
                class="code_item"
                :class="codeValue.length == 6 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[6] }}
            </div>
            <div
                class="code_item"
                :class="codeValue.length == 7 && inputFocus ? 'code_item_active' : ''"
            >
                {{ codeValue[7] }}
            </div>
        </div>
        <!-- input框：控制长度 -->
        <el-input
            class="input_code"
            :value="codeValue"
            :maxlength="8"
            @blur="codeInputBlur"
            @focus="codeInputFocus"
            @input="codeInputChange"
        >
        </el-input>
    </div>
</template>

<script>
export default {
    // props:['codeValue'],
    props:{
        codeValue:{
            type:String,
            default:""
        }
    },
    data() {
        return {
            visible: false,
            inputFocus: false,
            // codeValue: '',
            title: '密码输入'
        }
    },
    methods: {
        // init() {
        //     this.visible = true
        // },
        // 校验输入的验证码/密码
        // confirm() {
        //     if (this.codeValue.length === 6) {
        //         this.$message({
        //             showClose: true,
        //             message: '验证成功！',
        //             type: 'success'
        //         })
        //         this.codeValue = ''
        //         this.visible = false
        //     } else {
        //         this.$message({
        //             showClose: true,
        //             message: '验证码不正确！',
        //             type: 'error'
        //         })
        //     }
        // },
        // 验证码输入框
        codeInputChange(e) {
            // 判断是否输入值
            if (e) {
                // 正则判断是否都是数字，如果都是数字则赋值,否则
                if ((/^\+?[0-9][0-9]*$/).test(e)) {
                    // this.codeValue = e
                    this.$emit('update:codeValue', e)
                }else{
                    this.$message.error('请输入数字')
                }
            } else {
                this.codeValue = ''
            }
        },
        // 验证码输入框失去焦点
        codeInputBlur() {
            this.inputFocus = false
        },
        // 验证码输入框获取到焦点
        codeInputFocus() {
            this.inputFocus = true
        },
    },
    computed:{
        // codeValue:{
        //     set(v){
        //         this.$emit('update:codeValue',v)
        //     }
        // }
    }
}
</script>

<style lang="less" scoped>
.input_box {
    margin-top: 20px;
    height: 100px;
}

.input_code {
    position: absolute;
    top: 0;
    left: 0;
}

.btn {
    position: absolute;
    top: 70px;
    left: 0;
}

.codes {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-start;

    .code_item {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border: 1px solid #f0f0f0;
        margin-right: 10px;
    }
}

.code_item_active {
    border: 2px solid #F23026 !important;
    // box-sizing: border-box;
}

// 隐藏input
.input_box {
    ::v-deep .el-input__inner {
        width: 100%;
        height: 50px !important;
        background-color: transparent;
        border: none;
        color: transparent;
    }
}

::v-deep .el-dialog {
    min-height: 100px;
}
</style>
