//auth.js
import * as api from '@/api/api'
const user = {
    namespaced: true, // 开启命名空间
    state: {
        user: null,
    },
    mutations: {
        SET_USER: (state, user) => {
            state.user = user
        },
    },
    actions: {
        // 登录
        Login({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                api.login(userInfo).then(res => {
                    commit('SET_USER', res.data)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        Logout({ commit, state }) {
            return new Promise((resolve, reject) => {
                api.logout().then(() => {
                    commit('SET_USER', null)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}
export default user
