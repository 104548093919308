/**
 * Created by feili on 2018/5/11.
 */

import validate from './validate.js'

export default {
    /*qq号*/
    isQQ: (rule, value, callback) => {
        if ((value || '') != '') {
            if (!validate('qq', value)) {
                callback(new Error('您输入的QQ号不正确!'))
            } else {
                callback()
            }
        } else {
            callback();
        }
    },
    /*手机号*/
    isMobile: (rule, value, callback) => {
        if ((value || '') != '') {
            if (!validate('mobile', value)) {
                callback(new Error('您输入的手机号不正确!'))
            } else {
                callback()
            }
        } else {
            callback();
        }
    },
    /*座机号*/
    isPhone: (rule, value, callback) => {
        if ((value || '') != '') {
            if (!validate('phone', value)) {
                callback(new Error('您输入的座机号不正确!'))
            } else {
                callback()
            }
        } else {
            callback();
        }
    },
    /*身份证号*/
    isCardID: (rule, value, callback) => {
        if ((value || '') != '') {
            if (!validate('cardid', value)) {
                callback(new Error('您输入的身份证号不正确!'))
            } else {
                callback()
            }
        } else {
            callback();
        }
    },
    /*数字（正整数和0）*/
    isInteger: (rule, value, callback) => {
        if ((value || '') != '') {
            if (!validate('integer', value)) {
                callback(new Error('请输入数字!'))
            } else {
                callback()
            }
        } else {
            callback();
        }
    },
    /*保留两位小数*/
    isMoneynum: (rule, value, callback) => {
        if ((value || '') != '') {
            if (!validate('moneynum', value)) {
                callback(new Error('请输入正确的数字，最多保留两位小数!'))
            } else {
                callback()
            }
        } else {
            callback();
        }
    },
    isIdCard: (rule, card, callback) => {
        var coefficient = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        //validate 验证码
        var validate = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
        card = card.split('');
        if (card.length == 18) {
            var sum = 0;
            for (var i = 0; i < card.length - 1; i++) {
                sum += parseInt(card[i]) * parseInt(coefficient[i]);
                //alert(parseInt(coefficient[i]));
            }
            if (card[17] == validate[sum % 11]) {
                callback();
                // callback(new Error('身份证号码正确！'));
            } else {
                callback(new Error('身份证号码错误！'));
            }
        } else {
            callback(new Error('输入位数错误：要求输入18位数字，您输入了' + card.length + '位数！'));
        }
    },
}