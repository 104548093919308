<template>
    <div class="login">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
            <h3 class="title">蓝证实名证照(照相馆版)</h3>
            <el-form-item prop="username">
                <el-input prefix-icon="el-icon-user-solid"
                          v-model="loginForm.username"
                          type="text"
                          auto-complete="off"
                          placeholder="账号"
                >
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input prefix-icon="el-icon-key"
                          v-model="loginForm.password"
                          type="password"
                          auto-complete="off"
                          placeholder="密码"
                          @keyup.enter.native="handleLogin"
                >
                    <!--          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />-->
                </el-input>
            </el-form-item>
            <el-form-item style="width:100%;">
                <el-button
                    :loading="loading"
                    size="medium"
                    type="primary"
                    style="width:100%;"
                    @click.native.prevent="handleLogin"
                >
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                </el-button>
                <div style="float: right;" v-if="register">
                    <router-link class="link-type" :to="'/register'">立即注册</router-link>
                </div>
            </el-form-item>
        </el-form>
        <!--  底部  -->
        <div class="el-login-footer">
            <span>Copyright © 2024 landi All Rights Reserved.</span>
        </div>
    </div>
</template>

<script>

import CodeInput from '@/components/CodeInput'
// import { getCodeImg } from "@/api/login";
// import Cookies from "js-cookie";
// import { encrypt, decrypt } from '@/utils/jsencrypt'
import * as api from '@/api/api'

export default {
    name: "Login",
    components: {
        CodeInput // 祖册自定义子组件
    },
    data() {
        return {
            codeValue:"",
            codeUrl: "",
            loginForm: {
                username: "",
                password: "",
                rememberMe: false,
                code: "",
                uuid: ""
            },
            loginRules: {
                username: [
                    {required: true, trigger: "blur", message: "请输入您的账号"}
                ],
                password: [
                    {required: true, trigger: "blur", message: "请输入您的密码"}
                ]
            },
            loading: false,
            // 验证码开关
            captchaEnabled: true,
            // 注册开关
            register: false,
            redirect: undefined
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true
        }
    },
    created() {
    },
    methods: {
        handleLogin() {
            var _this = this
            // this.$refs.loginForm.validate(valid => {
            //     if (valid) {
            //         api.login({
            //             username: this.loginForm.username,
            //             password: this.loginForm.password
            //         }).then(res => {
            //             console.info(res)
            //             this.$router.push({path: this.redirect || "/"}).catch(() => {
            //             });
            //             localStorage.setItem("token", res.data.token)
            //             localStorage.setItem("nickName", res.data.nickName)
            //         });
            //     }
            // });

            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.$store.dispatch("user/Login", this.loginForm).then(() => {
                        this.$router.push({path: this.redirect || "/photo"}).catch(() => {
                        });
                    }).catch(() => {
                        // this.loading = false;
                        // if (this.captchaEnabled) {
                        //     this.getCode();
                        // }
                    });
                }
            });


        }
    }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("@/assets/images/login-background.jpg");
    background-size: cover;
}

.title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
}

.login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 400px;
    padding: 25px 25px 5px 25px;

    .el-input {
        height: 38px;

        input {
            height: 38px;
        }
    }

    .input-icon {
        height: 39px;
        width: 14px;
        margin-left: 2px;
    }
}

.login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
}

.login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
        cursor: pointer;
        vertical-align: middle;
    }
}

.el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
}

.login-code-img {
    height: 38px;
}
</style>
