<template>
  <div class="app-container">
    <el-row :gutter="50">
      <el-col :span="leftCol" style="height: 700px;">
        <div>
          <el-tabs v-model="activeRejectTypeId" @tab-click="handleClickRejectType" tab-position="top"
                   style="height: 500px">
            <el-tab-pane :label="item.name" :name="item.id + ''" v-for="(item) in rejectTypeList" :key="item.id">
              <el-checkbox-group v-model="rejectContentIds" id="checklist">
                <el-checkbox :label="item.id" class="checkitem" v-for="item in rejectContentOptions" :key="item.id">
                  <div style="white-space:pre-wrap; ">{{ item.content }}</div>
                </el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>
            <!-- <el-tab-pane label="配置管理" name="second">bbb</el-tab-pane>
            <el-tab-pane label="角色管理" name="third">ccc</el-tab-pane>
            <el-tab-pane label="定时任务补偿" name="fourth">ddd</el-tab-pane> -->
          </el-tabs>
<!--          <div style="margin-top: 50px">-->
<!--            <el-button type="danger" @click="handleReject">不合格</el-button>-->
<!--          </div>-->
        </div>
      </el-col>
      <el-col :span="midCol" style="height: 700px;">
<!--        <el-empty v-if="isNoPic" :image-size="441" :description="nopicDesc"></el-empty>-->
<!--        <el-image v-else :src="img"  class="middleimg"/>-->

        <div style="display: flex;flex-direction: column;align-items: center;justify-content: space-between">
          <div>
            <div style="height: 50px;font-weight: 600;">{{ id }}</div>
            <el-empty v-if="isNoPic" :image-size="400" :description="nopicDesc"></el-empty>
            <el-image  v-else :src="img" style="height: 500px"  class="middleimg" fit="contain"/>
          </div>
        </div>
      </el-col>
      <el-col :span="rightCol" style="height: 700px;">
        <el-descriptions class="margin-top" title="信息统计" :column="1" border direction="" :labelStyle="labelStyle">
          <template slot="extra">
            <el-button type="primary" size="small" @click="handleGetStatisticsInfo">更新</el-button>
          </template>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              全部待审核
            </template>
            {{ totalDshCnt }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              我的今天已审核
            </template>
            {{ myTdYshCnt }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              我的总审核
            </template>
            {{ myTotalYshCnt }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :span="leftCol">
        <el-button type="danger" @click="handleReject">不合格</el-button>
      </el-col>
      <el-col :span="midCol">
        <el-button type="success" @click="handlePass">保存</el-button>
      </el-col>
      <el-col :span="rightCol">
      </el-col>
    </el-row>
    <!-- <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form> -->

    <!--    <el-row :gutter="10" class="mb8">-->
    <!--      <el-col :span="1.5">-->
    <!--        <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleDshRject"-->
    <!--                   v-hasPermi="['smzz:sbkdsh:remove']" :disabled="isNoPic">拒绝-->
    <!--        </el-button>-->
    <!--      </el-col>-->
    <!--      <el-col :span="1.5">-->
    <!--        <el-button type="success" plain icon="el-icon-edit" size="mini" @click="handleDshPass"-->
    <!--                   v-hasPermi="['smzz:sbkdsh:edit']" :disabled="isNoPic">通过-->
    <!--        </el-button>-->
    <!--      </el-col>-->
    <!--    </el-row>-->

    <!-- <el-table v-loading="loading" :data="sbkdshList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="id" align="center" prop="id" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
            v-hasPermi="['smzz:sbkdsh:edit']">修改</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
            v-hasPermi="['smzz:sbkdsh:remove']">删除</el-button>
        </template>
      </el-table-column>
    </el-table> -->

    <!--  <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
      @pagination="getList" /> -->

    <!-- 添加或修改待审核对话框 -->
    <!-- <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>

import * as api from "@/api/api";
import {getStatisticsInfo, rejectContentList, updateSbk} from "@/api/api";

export default {
  name: "Sbkdsh",
  data() {
    return {
      leftCol:8,
      midCol:8,
      rightCol:8,
      labelStyle: {
        width: "200px"
      },
      nopicDesc:"暂无图片",
      id: "",
      rejectContentIds: [],
      rejectContentOptions: [],
      isNoPic: true,
      img: "",
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 待审核表格数据
      sbkdshList: [],
      // 弹出层标题
      title: "",
      activeRejectTypeId: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      rejectTypeList: [],
      totalDshCnt :0,
      myTotalYshCnt :0,
      myTdYshCnt :0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {}
    };
  },
  created() {
    // this.getList();
    // this.dshItem();
    this.handleGetDsh();
    this.handleRejectTypeList();
    this.handleGetStatisticsInfo();
    // this.handlerGetDshCount()
  },
  methods: {
    handleGetStatisticsInfo() {
      // this.loading = true;
      getStatisticsInfo().then(res => {
        console.info(res)
        // this.dzzCnt = res.data.dzzCnt
        // this.yzzCnt = res.data.yzzCnt
        // this.yzzTdCnt = res.data.yzzTdCnt

        this.totalDshCnt =res.data.totalDshCnt
        this.myTotalYshCnt =res.data.myTotalYshCnt
        this.myTdYshCnt =res.data.myTdYshCnt
      });
    },
    handlerGetDshCount() {
      this.loading = true;
      getDshCount().then(response => {
        console.info(response)
      });
    },

    dshItem() {
      this.loading = true;
      getDshItem().then(response => {
        //   if (response.data != undefined) {
        //     this.isNoPic = false;
        //     this.img = process.env.VUE_APP_FILE_SERVER_URL + response.data.filePath
        //     this.id = response.data.id
        //   } else {
        //     this.isNoPic = true;
        //     this.img = require("@/assets/images/nopic.jpg");
        //     this.$modal.msgSuccess(response.msg);
        //   }
        //   this.loading = false;
        // }).catch((err) => {
        //   console.info(err)
        // });


        if (res.data != undefined) {
          this.id = res.data.id
          var filePath = res.data.filePath

          if (filePath == '' || filePath == undefined || filePath == null) {
            this.isNoPic = true;
            this.nopicDesc = "暂无图片"
          } else {
            this.isNoPic = false;
            this.img = process.env.VUE_APP_FILE_SERVER_URL + filePath
          }
        } else {
          this.isNoPic = true;
          this.nopicDesc = "暂无数据"
          this.$modal.msgSuccess(response.msg);
        }
        this.loading = false;
      })
    },

    handleReject() {
      if (this.rejectContentIds.length == 0) {
        this.$modal.msgSuccess("请选择拒绝理由");
        return
      }
      var data = {
        id: this.id,
        photoStatus: 22,
        processStatus: 0,
        rejectContentIds: this.rejectContentIds
      }
      api.updateSbk(data).then(res => {
        console.info(res)
        this.handleGetDsh();
        this.$modal.msgSuccess("操作成功");
        this.rejectContentIds = [];
        this.loading = false;
      })
    },
    handlePass() {
      var data = {
        id: this.id,
        processStatus: 0,
        photoStatus: 21
      }
      api.updateSbk(data).then(res => {
        console.info(res)
        this.handleGetDsh();
        this.$modal.msgSuccess("操作成功");
        this.rejectContentIds = [];
        this.loading = false;
      })
    },
    handleGetDsh() {
      // var data={
      //   id:this.id,
      //   photoStatus:21,
      //   rejectContentIds:this.rejectContentIds
      // }
      api.getDsh().then(res => {
        // console.info(res)
        // if (res.data != undefined) {
        //   this.isNoPic = false;
        //   this.img = process.env.VUE_APP_FILE_SERVER_URL + res.data.oriFilePath
        //   this.id = res.data.id
        // } else {
        //   this.isNoPic = true;
        //   this.img = require("@/assets/images/nopic.jpg");
        //   this.$modal.msgSuccess(response.msg);
        // }
        // this.loading = false;


        if (res.data != undefined) {
          this.id = res.data.id
          var oriFilePath = res.data.oriFilePath
          if (oriFilePath == '' || oriFilePath == undefined || oriFilePath == null) {
            this.isNoPic = true;
            this.nopicDesc = "暂无图片"
          } else {
            this.isNoPic = false;
            this.img = process.env.VUE_APP_FILE_SERVER_URL + oriFilePath
          }
        } else {
          this.isNoPic = true;
          this.nopicDesc = "暂无数据"
          this.$modal.msgSuccess(res.msg);
        }
        this.loading = false;
      })
    },

    handleRejectTypeList() {
      var page = {
        pageNum: 1,
        pageSize: 100,
      }
      api.rejectTypeList(page).then(response => {
        console.info(response)
        this.rejectTypeList = response.data;
        console.info(this.rejectTypeList[0].id)
        this.activeRejectTypeId = this.rejectTypeList[0].id + ''
        setTimeout(this.handleClickRejectType, 100)
        // this.handleClickRejectType()
        this.loading = false;
      });
    },
    handleClickRejectType() {
      var page = {
        pageNum: 1,
        pageSize: 100,
        rejectTypeId: this.activeRejectTypeId
      }
      api.rejectContentList(page).then(response => {
        this.rejectContentOptions = response.data;
        this.loading = false;
      });
    },

    handleDshRject() {
      if (this.rejectContentIds.length == 0) {
        this.$modal.msgSuccess("请选择拒绝理由");
        return
      }
      this.loading = true;
      dshReject({
        id: this.id,
        rejectContentIds: this.rejectContentIds
      }).then(response => {
        this.dshItem();
        this.$modal.msgSuccess("操作成功");
        this.rejectContentIds = [];
        this.loading = false;
      });
    },

    handleDshPass() {
      this.loading = true;
      dshPass(this.id).then(response => {
        this.dshItem();
        this.$modal.msgSuccess("操作成功");
        this.loading = false;
      });
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null

      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      console.info(this.ids)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
  }
};
</script>
<style scoped>
#checklist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.middleimg {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.6)
}
.checkitem {
  margin: 10px;
}
</style>
