import service from "@/utils/request";
export function login(params) {
    return service({
        method: 'post',
        url: '/storeLogin',
        data: params
    })
}

export function logout(params) {
    return service({
        method: 'post',
        url: '/logout',
        data: params
    })
}

export function getDicts(dictType) {
    return service({
        url: '/system/dict/data/type/' + dictType,
        method: 'get'
    })
}

export function uploadByQrCode(params) {
    return service({
        method: 'post',
        url: '/api/v1/sbkItem/uploadByQrCode',
        data: params
    })
}

export function uploadPhotoByShootCode(params) {
    return service({
        method: 'post',
        // url: '/api/v1/photo/uploadPhotoByShootCode',
        url: '/api/photo/v2/uploadPhotoByShootCode',
        data: params
    })
}

export function rejectTypeList(params) {
    return service({
        method: 'get',
        url: '/zz/sbkzz/rejectType/list',
        params: params
    })
}

export function rejectContentList(params) {
    return service({
        method: 'post',
        url: '/zz/sbkzz/rejectContent/list',
        data: params
    })
}

export function makeFail(params) {
    return service({
        method: 'post',
        url: '/zz/sbkzz/makeFail',
        data: params
    })
}

export function getDsh(params) {
    return service({
        method: 'get',
        url: '/zz/sbkzz/getDsh',
        params: params
    })
}
export function getDzz(params) {
    return service({
        method: 'post',
        url: '/zz/sbkzz/getDzz',
        data: params
    })
}

export function switchPhoto(params) {
    return service({
        method: 'post',
        url: '/zz/sbkzz/switchPhoto',
        data: params
    })
}

export function makePass(params) {
    return service({
        method: 'post',
        url: '/zz/sbkzz/makePass',
        data: params
    })
}

export function saveDict(params) {
    return service({
        method: 'post',
        url: '/zz/sbkzz/saveDict',
        data: params
    })
}
export function getDict() {
    return service({
        method: 'get',
        url: '/zz/sbkzz/getDict'
    })
}

export function updateOriAndAIzz(params) {
    return service({
        method: 'post',
        url: '/zz/sbkzz/updateOriAndAIzz',
        data: params
    })
}

export function getUploadInfo() {
    return service({
        method: 'get',
        url: '/zz/sbkzz/getUploadInfo'
    })
}

export function getStatisticsInfo() {
    return service({
        method: 'get',
        url: '/zz/sbkzz/getStatisticsInfo'
    })
}

export function getPhotoSourceList() {
    return service({
        method: 'get',
        url: '/zz/sbkzz/photoSource/list'
    })
}

export function updatePwd(params) {
    return service({
        method: 'put',
        url: `/system/user/profile/updatePwd?oldPassword=${params.oldPassword}&newPassword=${params.newPassword}`,
    })
}




