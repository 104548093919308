import service from "@/utils/request";

export function query(params) {
    return service({
        method: 'post',
        url: '/api/v1/sbkItem/listByCreateBy',
        data: params
    })
}

export function add(params) {
    return service({
        method: 'post',
        url: '/sbkItem',
        data: params
    })
}

export function update(params) {
    return service({
        method: 'put',
        url: '/sbkItem',
        data: params
    })
}

export function del(ids) {
    return service({
        method: 'delete',
        url: '/sbkItem/'+ids
    })
}
export function get(id) {
    return service({
        method: 'get',
        url: '/sbkItem/'+id
    })
}

export function upload(ids) {
    return service({
        method: 'get',
        url: '/sbkItem/upload/'+ids
    })
}

export function updatePwd(params) {
    return service({
        method: 'post',
        url: 'system/user/profile/updatePwd',
        data: params
    })
}
