<template>
    <div class="app-container">
        ddddddddd
    </div>
</template>

<script>
import CountDown from "@/components/CountDown/index.vue";
import * as api from "@/api/api";
import {getDzzOri, getStatisticsInfo, rejectContentList, updateSbk, getPhotoSourceList} from "@/api/api";
import {mapState} from "vuex";
import {mapMutations} from "vuex";

export default {
    name: "dzz_d",
    components: {
        CountDown
    },
    data() {
        return {
            // photoSourceList:[],
            photoSources: [],
            photoSourceMap: {},
            btnDisabled: true,
            // notifyme:false,
            countDown: 0,
            photoType: '',
            leftCol: 8,
            midCol: 8,
            rightCol: 8,
            labelStyle: {
                width: "200px"
            },
            id: "",
            totalDzzCnt: 0,
            myTotalYzzCnt: 0,
            myTdYzzCnt: 0,
            nopicDesc: "暂无图片",
            rejectContentIds: [],
            rejectContentOptions: [],
            isNoPic: true,
            img: "",
            // img: require("@/assets/images/nopic.jpg"),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            oriFlag: false,
            // 总条数
            total: 0,
            // 待审核表格数据
            sbkdshList: [],
            // 弹出层标题
            title: "",
            activeRejectTypeId: "",
            photoSource:null,
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
            },
            rejectTypeList: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {}
        };
    },
    created() {
        this.photoType = localStorage.getItem("photoType") ? localStorage.getItem("photoType") : 1
        // this.notifyme = localStorage.getItem("notifyme")=='true' ? true : false
        this.handleGetDzz();
        this.handleRejectTypeList();
        this.handleGetStatisticsInfo();
        this.handleGetPhotoSourceList();

    },
    methods: {
        ...mapMutations(['updateNotifyme','updatePhotoSourceList']),
        changeNotify(val) {
            // this.updateNotifyme(val)
            // localStorage.setItem("notifyme", val)
        },
        handleGetPhotoSourceList() {
            let that=this
            getPhotoSourceList().then(res => {
                this.photoSources = res.data
            })
        },
        changePhotoType(photoType) {
            localStorage.setItem("photoType", photoType)
            this.handleGetDzz();
        },
        handleGetStatisticsInfo() {
            getStatisticsInfo().then(res => {
                this.totalDzzCnt = res.data.totalDzzCnt
                this.myTotalYzzCnt = res.data.myTotalYzzCnt
                this.myTdYzzCnt = res.data.myTdYzzCnt
            });
        },
        countDownEnd() {
            this.countDown = 0
            this.isNoPic = true
            this.nopicDesc = '暂无图片'
            this.btnDisabled = true
            this.id = ''
        },


        handleReject() {
            if (this.rejectContentIds.length == 0) {
                this.$modal.msgSuccess("请选择拒绝理由");
                return
            }
            var data = {
                id: this.id,
                photoStatus: 24,
                photoType: this.photoType,
                rejectContentIds: this.rejectContentIds
            }
            api.makeFail(data).then(res => {
                this.handleGetDzz();
                this.$modal.msgSuccess("操作成功");
                this.rejectContentIds = [];
                this.loading = false;
                this.handleGetStatisticsInfo()
            })
        },
        handlePass() {
            var data = {
                id: this.id,
                photoType: this.photoType
            }
            api.makePass(data).then(res => {
                this.handleGetDzz();
                this.$modal.msgSuccess(res.msg);
                this.rejectContentIds = [];
                this.loading = false;
                this.handleGetStatisticsInfo()
            })
        },

        handleGetDzz() {
            let that = this
            api.getDzz({photoType: this.photoType,photoSourceList:this.photoSourceList}).then(res => {
                this.id = res.data.id
                this.photoSource=res.data.photoSource
                var filePath = res.data.aiFilePath || res.data.oriFilePath
                that.countDown = 600
                that.$nextTick(() => {
                    that.$refs.cd1.toCountDown();
                })
                that.btnDisabled = false
                this.isNoPic = false;
                this.img = process.env.VUE_APP_FILE_SERVER_URL + filePath
                this.loading = false;
            }).catch(e => {
                that.countDownEnd()
            })
        },
        switchPhoto() {
            let that = this
            this.oriFlag=!this.oriFlag
            api.switchPhoto({
                id: this.id,
                photoType: this.photoType,
                oriFlag:this.oriFlag
            }).then(res => {
                this.id = res.data.id
                this.photoSource=res.data.photoSource
                var filePath;
                if(that.oriFlag){
                    filePath=res.data.oriFilePath
                }else{
                    filePath=res.data.aiFilePath
                }
                this.isNoPic = false;
                this.img = process.env.VUE_APP_FILE_SERVER_URL + filePath
                this.loading = false;
            }).catch(e => {
                that.countDownEnd()
            })
        },
        handleRejectTypeList() {
            var page = {
                pageNum: 1,
                pageSize: 100,
            }
            api.rejectTypeList(page).then(response => {
                this.rejectTypeList = response.data;
                this.rejectTypeList.unshift({
                    id:0,
                    name:'热门'
                });
                this.activeRejectTypeId = this.rejectTypeList[0].id + ''
                setTimeout(this.handleClickRejectType, 100)
                // this.handleClickRejectType()
                this.loading = false;
            });
        },
        handleClickRejectType() {
            var params = {
                pageNum: 1,
                pageSize: 100,
            }
            if(this.activeRejectTypeId==0){
                params.ishot='Y'
            }else{
                params.rejectTypeId=this.activeRejectTypeId
            }
            api.rejectContentList(params).then(response => {
                this.rejectContentOptions = response.data;
                this.loading = false;
            });
        },
    },
    computed: {
        photoSourceTxt(){
            let ret=""
            this.photoSources.forEach(item=>{
                if(item.dictValue==this.photoSource){
                    ret= item.dictLabel
                }
            })
            return ret
        },
        notifyme: {
            get() {
                return this.$store.getters.getNotifyme
            },
            set(val) {
                this.updateNotifyme(val)
            }
        },
        photoSourceList: {
            get() {
                return this.$store.getters.getPhotoSourceList
            },
            set(val) {
                this.updatePhotoSourceList(val)
            }
        },
    }
};
</script>
<style scoped>
#checklist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.middleimg {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.6)
}

.checkitem {
    margin: 10px;
}

.desc-lable {
    width: 200px;
}

.type-box {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    /*justify-content: space-around;*/
    /*background-color: red;*/
}

.type-box div {

}
</style>
