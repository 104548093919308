import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path:"/login",
        name:"登录页",
        component:() => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
    },
    {
        path: "/",
        name: "首页",
        redirect: "/login",
        component: () => import(/* webpackChunkName: "about" */ '@/layout/Layout.vue'),
        children: [
            {
                path: "/personinfo",
                name: "用户信息",
                component: () => import(/* webpackChunkName: "about" */ '@/views/Personinfo.vue'),
            },
            {
                path: "/photo",
                name: "照片列表",
                component: () => import(/* webpackChunkName: "about" */ '@/views/photo.vue'),
            },
            {
                path: "/dsh",
                name: "待审核",
                component: () => import(/* webpackChunkName: "about" */ '@/views/dsh.vue'),
            },
            {
                path: "/photoUpload",
                name: "照片上传",
                component: () => import(/* webpackChunkName: "about" */ '@/views/photoUpload.vue'),
            },
            {
                path: "/uploadOriPhoto",
                name: "上传照片",
                component: () => import(/* webpackChunkName: "about" */ '@/views/uploadOriPhoto.vue'),
            },
            {
                path: "/sbkItem",
                name: "电脑拍照",
                component: () => import(/* webpackChunkName: "about" */ '@/views/sbkItem.vue'),
            },
        ]
    }

]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
