import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import modal from '@/utils/modal'
import dateUtil from '@/utils/dateUtil'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/landi.scss' // ruoyi css
import '@/assets/icons' // icon

import {  resetForm,getYYYYMMDDHHmmss} from "@/utils/tools";
import { download } from '@/utils/request'
import service from '@/utils/request'
//使用ElementUI
Vue.use(ElementUI);

import FileSaver from 'file-saver'
import XLSX from 'xlsx'

Vue.prototype.$FileSaver = FileSaver; //设置全局
Vue.prototype.$XLSX = XLSX; //设置全局

Vue.config.productionTip = false
Vue.prototype.resetForm = resetForm
Vue.prototype.$modal = modal
Vue.prototype.download = download
Vue.prototype.$dateUtil = dateUtil
Vue.prototype.service = service
// import Vuex from "vuex";
// Vue.use(Vuex)
import DictTag from '@/components/DictTag'
import CodeInput from '@/components/CodeInput'
import ImagePreview from "@/components/ImagePreview"
Vue.component('CodeInput', CodeInput)
Vue.component('DictTag', DictTag)
Vue.component('ImagePreview', ImagePreview)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
