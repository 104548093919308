<template>
  <div id="rxbd">
    <div>
      <el-upload class="upload-demo" drag :action="uploadUrl" :on-change="onChange" :on-success="uploadSuccess"
        :on-progress="onProgress" :auto-upload="true" :multiple="false" accept=".zip">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">111111111将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">请上传zip文件</div>
      </el-upload>
    </div>
    <div id="opt-btn">
      <el-button type="danger" @click="clearData">清空列表</el-button>
      <!-- <el-button type="success" @click="downloadData">下载对比结果</el-button> -->
      <el-button type="success" @click="handleExport">下载对比结果</el-button>
    </div>
    <div>
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="true" label-width="98px">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="queryParams.phone" placeholder="请输入手机号" clearable @keyup.enter.native="handleQuery" />
        </el-form-item>

        <el-form-item label="身份证号码" prop="id">
          <el-input v-model="queryParams.id" placeholder="请输入身份证号码" clearable
            @keyup.enter.native="handleQuery" />
        </el-form-item>
        <el-form-item label="结果" prop="result">
          <el-input v-model="queryParams.result" placeholder="请输入结果" clearable @keyup.enter.native="handleQuery" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="tableData" stripe style="width: 100%" id="dd" :cell-style="cellStyle">
        <el-table-column prop="id" label="身份证" width="250">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="200">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="">
        </el-table-column>
        <el-table-column prop="result" label="结果" width="100" :formatter="formatterStr">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="verify(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from 'xlsx'
import request from '@/utils/request'
import customValid from '@/utils/validate/customerValidate.js'
export default {
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/rxbd/uploadFile",
      tableData: [],
      queryParams: {},
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleQuery() {
      // this.queryParams.pageNum = 1;
      this.getList();
    },
    clearData() {
      request({
        url: '/rxbd/delAll',
        method: 'post',
      }).then(res => {
      })
      this.tableData = []
    },
    formatterStr(row) {
      let r = JSON.parse(row.result)
      return r.message
    },
    getList() {
      request({
        url: '/rxbd/list',
        data: this.queryParams,
        method: 'post'
      }).then(res => {
        this.tableData = res.data.data
        // if (res.data.code == 0) {
        //   this.tableData = res.data.data
        // } else {
        //   this.$message.error(res.message)
        // }
      })
    },
    verify(index, data) {
      request({
        url: '/rxbd/verify',
        method: 'post',
        data: data
      }).then(res => {
        console.info(res.data.code)
        if (res.data.code == 0) {
          this.tableData = this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    uploadSuccess(res) {
      if (res.code == 0) {
        res.data.forEach((item) => {
          var obj = eval('(' + item.result + ')');
          item.id = item.id + ";"
          if (obj.code == 0 && obj.message.indexOf("系统判断为同一人") >= 0) {
            item.r = '成功'
          } else {
            item.r = obj.message
          }
        });
        this.getList()
      } else {
        this.$message.error(res.message)
      }


    },
    cellStyle({ row }) {//根据测试结果动态调整单元格样式，成功-绿色，失败-红色，不支持-黄色
      let cellStyle;
      let r = JSON.parse(row.result)
      if (r.code != '0' || r.message.indexOf('系统判断为不是同一人') > -1) {
        cellStyle = 'background: red;color:white';
      }
      return cellStyle;
    },
    onProgress() {

    },
    handleExport() {
      this.download('/rxbd/export', {
        ...this.queryParams
      }, `${this.$dateUtil.getFullDateTime()}.xlsx`)
    },
    download1() {
      let tables = document.getElementById("ou");
      let table_book = this.$XLSX.utils.table_to_book(tables);
      var table_write = this.$XLSX.write(table_book, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        this.$FileSaver.saveAs(
          new Blob([table_write], { type: "application/octet-stream" }),
          "sheetjs.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, table_write);
      }
      return table_write;
    },
    downloadData: function () {
      this.exportExcel("dd", "file");
    },
    exportExcel: function (id, fileName) {
      var wb = XLSX.utils.table_to_book(document.querySelector("#" + id));
      var wt = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        saveAs(
          new Blob([wt], { type: "application/octet-stream" }),
          fileName + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wt);
      }
    },
    onChange(file, fileList) {
      this.fileList = fileList
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['zip']
      const isSuffix = whiteList.indexOf(fileSuffix.toLowerCase()) === -1
      const isLt10M = file.size / 1024 / 1024 > 10
      console.log('this.fileList:', this.fileList)
      if (isSuffix) {
        this.$message.error('上传文件只能是 zip格式')
        const currIdx = this.fileList.indexOf(file)
        this.fileList.splice(currIdx, 1)
        return
      }
      if (isLt10M) {
        // this.$message.error('上传文件大小不能超过 10MB')
        // const currIdx = this.fileList.indexOf(file)
        // this.fileList.splice(currIdx, 1)
        // return
      }
    }
  }
}
</script>
<style lang="less">
#rxbd {
  margin: 30px;
  padding: 30px;
}

#opt-btn {
  margin: 20px;
  display: flex;
}
</style>
