<template>
    <el-card>
      <div style="display: flex;align-items: center">
        <el-radio-group v-model="isCollapse" size="mini" style="margin-right: 20px;" @input="collapse">
          <el-radio-button :label="false" >展开</el-radio-button>
          <el-radio-button :label="true">收起</el-radio-button>
        </el-radio-group>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
          <el-breadcrumb-item v-for="(item,index) in $route.matched" :key="index" >
            {{ item.name }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-card>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: true,
    }
  },
  methods:{
    collapse(e){
      console.info(e)
      this.$emit('collapse', e);
    }
  }

}
</script>

<style>

</style>
