<template>
  <div class="">
    <el-form ref="loginForm" :model="form"  class="login-form" :rules="loginRules">
<!--      <h3 class="title">实名证照</h3>-->
      <el-form-item prop="oriPath" label="需要上传的原始照片目录">
        <el-input
            v-model="form.oriPath"
            type="text"
            auto-complete="off"
            placeholder="需要上传的原始照片目录"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="destPath" label="临时文件目录(例如 c:/tmp)">
        <el-input
            v-model="form.destPath"
            type="text"
            auto-complete="off"
            placeholder="临时文件目录"
            @keyup.enter.native="handleLogin"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="oriSuffix" label="相机文件名前缀(例如 IMG_)">
        <el-input
            v-model="form.oriSuffix"
            type="text"
            auto-complete="off"
            placeholder="相机文件名前缀"
            @keyup.enter.native="handleLogin"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="destSuffix" label="DT50设置文件名前缀(例如 hj0311_)">
        <el-input
            v-model="form.destSuffix"
            type="text"
            auto-complete="off"
            placeholder="DT50设置文件名前缀"
            @keyup.enter.native="handleLogin"
        >
        </el-input>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width:100%;"
            @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">保存</span>
          <span v-else>保存中...</span>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as api from '@/api/api'
import {updateOriAndAIzz} from "@/api/api";
export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      form:{
        // oriPath:"E:\\ori",
        // destPath:"E:\\dest",
        // oriSuffix:"IMG_",
        // destSuffix:"aaa_"

        oriPath:"",
        destPath:"",
        oriSuffix:"",
        destSuffix:""
      },

      loginRules: {
        oriPath: [
          { required: true, trigger: "blur", message: "请输入" }
        ],
        oriSuffix: [
          { required: true, trigger: "blur", message: "请输入" }
        ],
        destSuffix: [
          { required: true, trigger: "blur", message: "请输入" }
        ],
        destPath: [
          { required: true, trigger: "blur", message: "请输入" }
        ]
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined
    };
  },
  created() {
    this.handleGetUploadInfo()
  },
  methods: {
    handleGetUploadInfo(){
      this.loading = true;
      api.getUploadInfo().then(response => {
        this.loading = false;
        // this.$modal.msgSuccess("上传成功");
        this.form=response.data
      }).catch(e=>{
        this.loading = false;
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          api.updateOriAndAIzz(this.form).then(response => {
            this.loading = false;
            // this.$modal.msgSuccess("上传成功");
            let msg="成功上传:<font color='red'>"+response.data.successCnt+"</font>张照片</br>"
            if(response.data.errorFiles!=''){
              msg+="失败文件名:"+response.data.errorFiles
            }
            this.$alert(msg, '提示', {
              confirmButtonText: '确定',
              dangerouslyUseHTMLString: true
            });


            // this.form={
            //   oriPath:"",
            //   destPath:"",
            //   oriSuffix:"",
            //   destSuffix:""
            // }
          }).catch(e=>{
            this.loading = false;
          })
        }
      })

    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  height: 100%;
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
}
</style>
